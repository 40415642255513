.footerBg {
  background: #b9e0e9;
  border: #b9e0e9;
  padding: 3rem;
}
.textColor {
  color: #034ea2;
  font-family: Lato, serif;
}
.home {
  height: 100vh;
}

.videodemo {
  width: 100%;
}
.teamcards {
  height: 250px;
  object-fit: contain;
}

.teamdata {
  text-align: center;
  font: 16px bold sans-serif;
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
}
.txt-bg {
  background-color: #c7d7e2;
  border: none;
  padding: 0px 2px 2px 2px;
}

.logo-txt-color {
  color: #03989e;
}
.logo-txt-color1 {
  color: #106d70;
}
a {
  text-decoration: none !important;
  color: #4d2529;
}
.card-img-top {
  object-fit: contain;
}

.we-work {
  font-size: large;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .card-img-top {
    object-fit: contain;
    height: 200px;
  }
  .we-work {
    font-size: medium;
    font-weight: bold;
  }
}

.custom-font-19 {
  font-size: 19px;
}

.links {
  color: orange;
}

.loginForm {
  background-color: #343a40; /* Bootstrap's dark background */
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.adminHome {
  background-color: #343a40; /* Bootstrap's dark background */
  color: white;
  height: 100vh;
}
